  

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Zero Dot One AI React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Zero Dot One AI React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Images
import team1 from "assets/images/logo.jpeg";
import team2 from "assets/images/logo.jpeg";
import team3 from "assets/images/logo.jpeg";
import team4 from "assets/images/logo.jpeg";

function Team() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="white">
            Meet Our Exceptional Executive Team
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8}>
            Unlocking potential, exceeding expectations, and driving success – these are the hallmarks of our esteemed Executive Team. Comprised of visionary leaders with diverse expertise and unwavering commitment,
            our team is dedicated to achieving excellence in every endeavor.
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team1}
                name="Shital Patole"
                position={{ color: "info", label: "CEO" }}
                description="Shital Patole is the visionary leader at the helm of our startup. With a profound passion for innovation and a clear strategic vision, Shital guides our company toward achieving its ambitious goals."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team2}
                name="Bhrati Dholu"
                position={{ color: "info", label: "CTO" }}
                description="Bharti Dholu, our Chief Technology Officer, is the technological powerhouse of our team. With a robust background in software engineering and a penchant for cutting-edge innovation."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team3}
                name="Rajesh Senghani"
                position={{ color: "info", label: "CMO" }}
                description="Rajesh Senghani, our Chief Marketing Officer, is the creative architect behind our brand's compelling presence. With a nuanced understanding of market dynamics and consumer behavior.impactful marketing strategies that resonate with our target audience."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team4}
                name="Tushar Bhombe"
                position={{ color: "info", label: "Full Stack Developer" }}
                description="Tushar Bhombe is the technical cornerstone of our development team. As our Lead Developer, Tushar combines exceptional programming expertise with meticulous attention to detail to deliver high-quality software solutions."
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Team;
